<template>
  <section
    :class="{ 'collections-grid--dense': (size === 'small') }"
    class="collections-grid"
  >
    <template v-if="loading">
      <v-skeleton-loader
        v-for="n in Array(50)"
        :key="`collections-skeleton-loader-${n}`"
        class="nft-skeleton-loader"
        outlined
        type="image, list-item-two-line, divider, actions"
      />
    </template>
    <collection-card
      v-for="collection in collections"
      v-else
      :key="`collections-list-item-${collection.id}`"
      :imageHeight="size === 'small' ? 150 : 200"
      v-bind="collection"
    />
  </section>
</template>

<script>
export default {
  name: 'CollectionsList',
  props: {
    collections: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      required: true,
      default: () => (false),
    },
    size: {
      type: String,
      required: false,
      default: () => ('large'),
      validator: (val) => ['large', 'small'].includes(val),
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
.collections-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  grid-gap: 30px;

  &--dense {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
</style>
